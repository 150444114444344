import {
    List,
    TextField,
    TagField,
    DateField,
    Table,
    BooleanField,
    useTable,
} from "@pankod/refine";

import { FreelancerCount } from "components/freelancers"

import { Freelancer } from "interfaces";

const FreelancerList: React.FC = () => {
    const { tableProps } = useTable<Freelancer>();
    return (
        <List
            pageHeaderProps={{
                onBack: () => console.log("clicked"),
                subTitle: <FreelancerCount /> ,
            }}
        >
            <Table {...tableProps} rowKey="id">
                <Table.Column dataIndex="name" title="Name" /> 
                <Table.Column dataIndex="email" title="Email" /> 
                <Table.Column dataIndex="contact" title="Contact" />
                <Table.Column
                            key="is_active"
                            dataIndex="is_active"
                            title="is Active"
                            render={(value) => <BooleanField value={true} />}
                        /> 
            </Table>
        </List>
    );
};

export default FreelancerList;