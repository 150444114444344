import React from "react";
import { DecompositionTreeGraph } from "@ant-design/charts";

interface ChartProps {
    data: {
        title: any,
        ranges: any,
        measures: any,
        target: any,
    }[];
}

export const TreeGraph: React.FC<ChartProps> = () => {


  const data = {
    id: 'A0',
    value: {
      title: 'Team EC',
      items: [
        {
          text: 'We are the best',
        },
      ],
    },
    children: [
      {
        id: 'A1',
        value: {
          title: 'CEO',
          items: [
            {
              text: 'Krishnan Vignesh',
            },
            {
              text: 'Emp ID',
              value: '1432',
            },
          ],
        },
        children: [
          {
            id: 'A11',
            value: {
              title: 'Title 1',
              items: [
                {
                  text: 'Qwerty',
                },
                {
                  text: 'Emp ID',
                  value: '3546',
                },
              ],
            },
          },
          {
            id: 'A12',
            value: {
              title: 'Title 2',
              items: [
                {
                  text: 'Qwerty',
                },
                {
                  text: 'Value 1',
                  value: '30%',
                },
              ],
            },
          },
          {
            id: 'A13',
            value: {
              title: 'Title 3',
              items: [
                {
                  text: 'Qwerty',
                },
                {
                  text: 'Value 2',
                  value: '30%',
                },
              ],
            },
          },
        ],
      },
      {
        id: 'A2',
        value: {
          title: 'COO',
          items: [
            {
              text: 'Mahadev K',
            },
            {
              text: 'Emp ID',
              value: '3546',
            },
          ],
        },
      },
    ],
  };

  const config = {
    data,
    markerCfg: (cfg) => {
      const { children } = cfg;
      return {
        show: children?.length,
      };
    },
    behaviors: ['drag-canvas', 'zoom-canvas', 'drag-node'],
  };



    return <DecompositionTreeGraph {...config} />;
};