import { Refine } from "@pankod/refine";
import routerProvider from "@pankod/refine-react-router";

import "styles/antd.less";
import { dataProvider } from "@pankod/refine-supabase";

import authProvider from "./authProvider";
import { supabaseClient } from "utility";
import {
  Title,
  Header,
  Sider,
  Footer,
  Layout,
  OffLayoutArea,
} from "components/layout";

import {
  Login,
  Signup,
} from "pages/essentials";

import {
  Dashboard
} from "pages/dashboard";

import  {
  FreelancerList
} from "pages/freelancers";

import {
  TaskList,
  TaskCreate,
  TaskShow,
  EditTask
} from "pages/task"

import {
  UserList
} from "pages/user"

import { TeamOutlined, ScheduleOutlined, DeploymentUnitOutlined } from '@ant-design/icons';

function App() {
  return (
    <Refine
      dataProvider={dataProvider(supabaseClient)}
      authProvider={authProvider}
      LoginPage={Login}
      DashboardPage={Dashboard}
      routerProvider = {{
        ...routerProvider,
        routes: [
          {
            exact: true,
            component: Signup,
            path: "/signup",
          },
        ] as typeof routerProvider.routes,
      }}

      Title={Title}
      Header={Header}
      Sider={Sider}
      Footer={Footer}
      Layout={Layout}
      OffLayoutArea={OffLayoutArea}
      resources={[
                {
                  name: "freelancers",
                  list: FreelancerList,
                  icon: <TeamOutlined />
                },  
                {
                  name: "users",
                  list: UserList,
                  icon: <DeploymentUnitOutlined />
                },
                {
                  name: "tasks",
                  list: TaskList,
                  create: TaskCreate,
                  show: TaskShow,
                  edit: EditTask,
                  icon: <ScheduleOutlined />
                }
            ]}
    >

    </Refine>
  );
}

export default App;
