import React from "react";

export const Footer: React.FC = () => {
    return (
                <div
                    style={{
                        backgroundColor: "#182127",
                        height: "48px",
                        marginTop: "2rem",
                        color: "#fff",
                        fontWeight: "500",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    Ecstasy Creatives 2021
                </div>
    )
};
