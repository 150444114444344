import React from "react";
import { useList, useMany, Row, Col, Card, Typography } from "@pankod/refine";
import { ITask, ILabel, IPriority, IStatus, IAuthUser } from "interfaces";

import { TaskChart } from "components/task/pie";
import { TreeGraph } from "components/task/treeGraph";
import { TaskCalendar } from "components/task/calendar";
import { TaskTimeline } from "components/task/timeline";
import { TaskTrends } from "components/task/trends";
import { groupBy } from "helper";

const { Title, Text } = Typography;

export const Dashboard = () => {
    const taskList = useList<ITask>({
        resource: "tasks",
    })

    const labelIds = taskList.data?.data.map((item) => item.label) ?? [];
    const priorityIds = taskList.data?.data.map((item) => item.priority) ?? [];
    const assignedIds = taskList.data?.data.map((item) => item.users) ?? [];
    const statusIds = taskList.data?.data.map((item) => item.status) ?? [];


    const { data: labels } = useMany<ILabel>({
        resource: "label",
        ids: labelIds || [],
    });

    const { data: priority } = useMany<IPriority>({
        resource: "priority",
        ids: priorityIds || [],
    });

    const { data: assigned } = useMany<IAuthUser>({
        resource: "users",
        ids: assignedIds || [],
    });

    const { data: status } = useMany<IStatus>({
        resource: "status",
        ids: statusIds || [],
    });


    return (
        <>
        <Title level={2}>Task Status</Title>
        <Row style={{marginBottom: "3rem"}}>
            <Col span={24}>
                    <TaskTrends />
            </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xl={8} lg={12} md={24} sm={24} xs={24}>
                <Card>
                    <Title level={4}>Labels</Title>
                    <TaskChart
                            data={
                                labels?.data.map((i) => {
                                    return {
                                        type: i.title,
                                        value: groupBy(labelIds)[i.id],
                                    };
                                }) ?? []
                            }
                    />
                </Card>
            </Col>
            <Col xl={8} lg={12} md={24} sm={24} xs={24}>
                <Card>
                    <Title level={4}>Priorities</Title>
                    <TaskChart
                        data={
                            priority?.data.map((i) => {
                                return {
                                    type: i.title,
                                    value: groupBy(priorityIds)[i.id],
                                };
                            }) ?? []
                        }
                    />
                </Card>
            </Col>
            <Col xl={8} lg={12} md={24} sm={24} xs={24}>
                <Card>
                    <Title level={4}>Status</Title>
                    <TaskChart
                        data={
                            status?.data.map((i) => {
                                return {
                                    type: i.title,
                                    value: groupBy(statusIds)[i.id],
                                };
                            }) ?? []
                        }
                    />
                </Card>
            </Col>
        </Row>

        <Row style={{marginTop: "3rem"}}>
            <Col span={24}>
                <Card>
                    <Title level={4}>Task Calendar</Title>
                    <TaskCalendar />
                </Card>
            </Col>
        </Row>

        <Row gutter={[16,16]} style={{marginTop: "3rem"}}>
            <Col span={16}>
                <Card>
                    <Title level={4}>Decomposition Tree Graph</Title>
                    <TreeGraph />
                </Card>
            </Col>
            <Col span={8}>
                <Card>
                    <Title level={4}>Recent Timelines</Title>
                    <TaskTimeline />
                </Card> 
            </Col>   
        </Row>


        </>
    )
}